import React, { useEffect, useRef, useState, useReducer, useMemo } from "react";
import { useForm } from "react-hook-form";
import { post } from "@rails/request.js";
import { AnimatePresence, motion } from "framer-motion";

import TipTapArea from "./TipTapArea";
import SliderToggle from "./../shared/SliderToggle";
import RadioButtons from "./../shared/RadioButtons";
import { Toasts, useToasts } from "./../shared/Toast";
import { Contact } from "./Contact";
import { TextProof } from "./TextProof";
import { VideoProof } from "./VideoProof";
import { AudioProof } from "./AudioProof";

export const CapformShow = ({ initCapform, initProof, initContact }) => {
  const capform = initCapform.capform;
  const { welcome_title, welcome_text, thanks_title, thanks_text } = capform;
  const { toasts, setToasts, addToast } = useToasts();
  const [proof, setProof] = useState(initProof.proof);
  const [contact, setContact] = useState(initContact.contact);
  const [page, setPage] = useState(proof.uuid ? 'contact-page' : 'welcome-page');
  const [kind, setKind] = useState(proof.kind);

  const changeKind = (kind) => {
    setKind(kind);
    if (kind === null) {
      setPage('welcome-page');
      return;
    }
    setPage('prompt-page');
  }

  return (
    <div className="sm:pb-20 sm:pt-20 relative my-auto w-full max-w-lg mx-auto animate__animated animate__zoomIn animate_02s">
      <Toasts toasts={toasts} setToasts={setToasts} />
      <div className="relative sm:min-w-[500px] w-full border border-2 border-black overflow-hidden rounded-2xl border bg-white shadow-2xl">
        { page === 'prompt-page' && kind === 'text' && <TextProof
          initCapform={initCapform}
          addToast={addToast}
          setPage={setPage}
          setProof={setProof}
          proof={proof}
        /> }
        { page === 'prompt-page' && kind === 'video' && <VideoProof
          initCapform={initCapform}
          addToast={addToast}
          setPage={setPage}
          setProof={setProof}
          proof={proof}
        /> }
        { page === 'prompt-page' && kind === 'audio' && <AudioProof
          initCapform={initCapform}
          addToast={addToast}
          setPage={setPage}
          setProof={setProof}
          proof={proof}
        /> }
        { page === 'contact-page' && <Contact
          proof={proof}
          initCapform={capform}
          addToast={addToast}
          contact={contact}
          setContact={setContact}
          setPage={setPage}
        /> }
        { page === 'welcome-page' && <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
            className="p-4"
          >
            <h1 className="text-lg font-medium sm:text-3xl">
              {welcome_title}
            </h1>
            <div className="content mt-4" dangerouslySetInnerHTML={{__html: welcome_text}} />
            <div className="mt-4 flex flex-col gap-2">
              <button onClick={() => changeKind('video')} className="group relative inline-flex h-10 items-center justify-center overflow-hidden rounded-md bg-ssblue px-3 py-2 text-white border-2 border-black text-sm">
                <span className="absolute h-0 w-0 bg-ssblue-alt transition-all duration-300 group-hover:h-full group-hover:w-full"></span>
                <span className="relative">
                  Upload a Video
                </span>
              </button>
              <button onClick={() => changeKind('audio')} className="group relative inline-flex h-10 items-center justify-center overflow-hidden rounded-md bg-sspurple px-3 py-2 text-white border-2 border-black text-sm">
                <span className="absolute h-0 w-0 bg-sspurple-alt transition-all duration-300 group-hover:h-full group-hover:w-full"></span>
                <span className="relative">
                  Upload Audio
                </span>
              </button>
              <button onClick={() => changeKind('text')} className="group relative inline-flex h-10 items-center justify-center overflow-hidden rounded-md bg-ssorange px-3 py-2 text-white border-2 border-black text-sm">
                <span className="absolute h-0 w-0 bg-ssorange-alt transition-all duration-300 group-hover:h-full group-hover:w-full"></span>
                <span className="relative">
                  Write Text
                </span>
              </button>
            </div>
          </motion.div>
        </AnimatePresence> }
        { page === 'thanks-page' && <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
            className="p-4"
          >
            <h1 className="text-lg font-medium sm:text-3xl">
              {thanks_title}
            </h1>
            <div className="content mt-4" dangerouslySetInnerHTML={{__html: thanks_text}} />
          </motion.div>
        </AnimatePresence> }
      </div>
    </div>
  );
};
