import React, { useEffect, useRef, useState, useReducer, useMemo } from "react";
import { useForm } from "react-hook-form";
import { post, patch } from "@rails/request.js";
import { RiAsterisk } from "react-icons/ri";
import { AnimatePresence, motion } from "framer-motion";

import TipTapArea from "./TipTapArea";
import SliderToggle from "./../shared/SliderToggle";
import RadioButtons from "./../shared/RadioButtons";
import { VideoUploader } from "./../shared/VideoUploader";
import { Toasts, useToasts } from "./../shared/Toast";

export const VideoProof = ({ initCapform, proof, setProof, setPage, addToast }) => {
  const capform = initCapform.capform;
  const { welcome_title, welcome_text, prompt_text } = capform;
  const {
    formState,
    register,
    unregister,
    watch,
    reset,
    handleSubmit,
    setValue,
    getValues,
    setError,
    setFocus,
  } = useForm({
    defaultValues: {
      kind: proof.kind,
      headline: proof.headline,
      uuid: proof.uuid,
      video_url: proof.video_url,
    },
  });

  const changeKind = (kind) => {
    setValue('kind', kind);
    if (kind === null) {
      setPage('welcome-page');
      return;
    }
    setPage('prompt-page');
  }

  const { errors, isDirty, dirtyFields, isLoading, isValid, isValidating } =
    formState;

  const handleUpdate = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true });
  };

  const handleResponse = async (response, patchpost) => {
    if (response.ok) {
      const json = await response.json;
      reset(json.proof);
      setProof(json.proof);
      setPage('contact-page');
      addToast('success', "Saved!")
      if (patchpost === 'post') { history.pushState({},'',`/cf/${capform.uuid}/${json.proof.uuid}`) }
    } else {
      addToast('error', "Error saving proof")
      console.log("bad", response);
    }
  }

  useEffect(() => {
    register('video_url', { required: true })
    return () => {
      unregister('video_url')
    }
  }, [])


  const onSubmit = async (data) => {
    if (proof.uuid) {
      const response = await patch(`/api/v1/proofs/${proof.uuid}/update_from_capform`, {
        body: {
          capform_uuid: capform.uuid,
          proof: {
            video_url: data.video_url,
            headline: data.headline
          }
        },
        contentType: "application/json",
        responseKind: "json",
      });
      handleResponse(response, 'patch');
    } else {
      const response = await post(`/api/v1/proofs/create_from_capform`, {
        body: {
          capform_uuid: capform.uuid,
          proof: {
            video_url: data.video_url,
            kind: 'video',
            headline: data.headline
          }
        },
        contentType: "application/json",
        responseKind: "json",
      });
      handleResponse(response, 'post');
    }
  };

  const handleVideoUrlChange = (url) => {
    setValue('video_url', url, { shouldDirty: true, shouldValidate: true });
  }

  const video_url = getValues().video_url;

  return <AnimatePresence>
    <motion.div
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0 }}
    >
      <div className="p-4 flex flex-col gap-y-2 animate__animated animate__slideInUp animate_02s">
        <div className='flex gap-2'>
          <button onClick={() => changeKind(null)} className="border shadow-sm rounded-full p-2 h-9 w-9 flex mx-auto"><svg xmlns="http://www.w3.org/2000/svg" className='h-5 w-5' viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg></button>
          { proof.uuid && <button onClick={() => setPage('contact-page')} className="border shadow-sm rounded-full p-2 h-9 w-9 flex mx-auto"> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5"> <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
</svg>
</button> }
        </div>
        <div className="content mt-4" dangerouslySetInnerHTML={{__html: prompt_text}} />
        <label className='mt-4 sm:mt-0 block text-sm font-medium text-gray-500'>
          <div className="flex items-center gap-2 relative">
            Headline
            <div className="text-gray-400">
              <RiAsterisk className='w-4 h-4 text-ssred' />
            </div>
            { errors.headline && <div className="text-red-500 text-sm">This field is required</div> }
          </div>
        </label>
        <input
          type="text"
          autoFocus={true}
          {...register("headline", { required: true })}
          className="w-full border border-gray-300 rounded-md px-3 py-2 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-ssblue"
        />
        <VideoUploader setUrl={handleVideoUrlChange} initialUrl={video_url} />
        <button onClick={handleSubmit(onSubmit)} className="group relative inline-flex h-10 items-center justify-center overflow-hidden rounded-md bg-ssblue px-3 py-2 text-white border-2 border-black text-sm">
          <span className="absolute h-0 w-0 bg-ssblue-alt transition-all duration-300 group-hover:h-full group-hover:w-full"></span>
          <span className="relative">
            Continue
          </span>
        </button>
      </div>
    </motion.div>
  </AnimatePresence>
}
