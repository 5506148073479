import React, { useEffect, useRef, useState, useReducer, useMemo } from "react";
import { useForm } from "react-hook-form";
import { post, patch } from "@rails/request.js";
import { RiAsterisk } from "react-icons/ri";
import { AnimatePresence, motion } from "framer-motion";

import TipTapArea from "./TipTapArea";
import SliderToggle from "./../shared/SliderToggle";
import RadioButtons from "./../shared/RadioButtons";
import { Toasts, useToasts } from "./../shared/Toast";
import { ImageUploader } from "./../shared/ImageUploader";

export const Contact = ({ initCapform, proof, contact, setContact, setPage, addToast }) => {
  const capform = initCapform;
  const { collect_avatar, collect_email, collect_job_title, collect_company_name, collect_company_logo, collect_company_team } = capform;
  const {
    formState,
    register,
    unregister,
    watch,
    reset,
    handleSubmit,
    setValue,
    getValues,
    setError,
    setFocus,
  } = useForm({
    defaultValues: {
      name: contact.name,
      title: contact.title,
      avatar: contact.avatar,
      company_logo: contact.company_logo,
      email: contact.email,
      company_name: contact.company_name,
      company_team: contact.company_team,
    },
  });

  const { errors, isDirty, dirtyFields, isLoading, isValid, isValidating } =
    formState;

  const handleUpdate = (key, val) => {
    setValue(key, val, { shouldDirty: true, shouldValidate: true });
  };

  const handleAvatarChange = (url) => {
    setValue('avatar', url, { shouldDirty: true, shouldValidate: true });
  }

  const handleCompanyLogoChange = (url) => {
    setValue('company_logo', url, { shouldDirty: true, shouldValidate: true });
  }

  useEffect(() => {
    if (collect_avatar !== 'Do not collect') {
      register('avatar', { required: collect_avatar === 'Required' });
    }
    if (collect_company_logo !== 'Do not collect') {
      register('company_logo', { required: collect_company_logo === 'Required' });
    }
    return () => {
      if (collect_avatar !== 'Do not collect') {
        unregister('avatar');
      }
      if (collect_company_logo !== 'Do not collect') {
        unregister('company_logo');
      }
    }
  }, []);

  const handleResponse = async (response, patchpost) => {
    if (response.ok) {
      const json = await response.json;
      reset(json.contact);
      setContact(json.contact);
      addToast('success', "Saved!")
      setPage('thanks-page');
    } else {
      addToast('error', "Error saving contact")
      console.log("bad", response);
    }
    history.pushState({},'',`/cf/${capform.uuid}/${proof.uuid}/thanks`)
  }

  const onSubmit = async (data) => {
    if (contact.id) {
      const response = await patch(`/api/v1/contacts/${contact.id}`, {
        body: {
          id: contact.id,
          contact: {
            proof_uuid: proof.uuid,
            capform_uuid: capform.uuid,
            ...data
          }
        },
        contentType: "application/json",
        responseKind: "json",
      });
      handleResponse(response, 'patch');
    } else {
      const response = await post(`/api/v1/contacts/create_from_capform`, {
        body: {
          contact: {
            proof_uuid: proof.uuid,
            capform_uuid: capform.uuid,
            ...data
          }
        },
        contentType: "application/json",
        responseKind: "json",
      });
      handleResponse(response, 'post');
    }
  };

  const avatar = getValues().avatar;
  const company_logo = getValues().company_logo;

  return <AnimatePresence>
    <motion.div
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0 }}
    >
      <div className="p-4 flex flex-col gap-y-2 animate__animated animate__slideInUp animate_02s">
        <button onClick={() => setPage('prompt-page')} className="border shadow-sm rounded-full p-2 h-9 w-9 flex mx-auto"><svg xmlns="http://www.w3.org/2000/svg" className='h-5 w-5' viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg></button>
        <div className="p-4 flex flex-col gap-y-2 animate__animated animate__slideInUp animate_02s">
          <div className='flex flex-col gap-y-4'>
            <div className='grid gap-x-4 grid-cols-1 sm:grid-cols-5'>
              { collect_avatar !== 'Do not collect' && (
                <div className='col-span-2'>
                  <label className='mt-4 sm:mt-0 block text-sm font-medium text-gray-500'>
                  <div className="flex items-center gap-2 relative">
                      <span>Your Avatar</span>
                      { collect_avatar === 'Required' && (
                        <div className="text-gray-400">
                          <RiAsterisk className='w-4 h-4 text-ssred' />
                        </div>
                      )}
                    </div>
                  </label>
                  <ImageUploader initialImageUrl={avatar} setUrl={handleAvatarChange} />
                </div>
              )}

              <div className='col-span-3'>
                <label className='mt-4 sm:mt-0 block text-sm font-medium text-gray-500'>
                  <div className="flex items-center gap-2 relative">
                    <span>Name</span>
                    <div className="text-gray-400">
                      <RiAsterisk className='w-4 h-4 text-ssred' />
                    </div>
                    { errors.name && <div className="text-red-500 text-sm">This field is required</div> }
                  </div>
                </label>
                <input
                  type='text'
                  {...register("name", { required: true })}
                  autoFocus={true}
                  placeholder='Your Name'
                  spellCheck='false'
                  className='border border-gray-300 rounded-md px-3 py-2 w-full placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-ssblue'
                />
              </div>
            </div>
            { collect_email !== 'Do not collect' && (
              <div>
                <label className='mt-4 sm:mt-0 block text-sm font-medium text-gray-500'>
                  <div className="flex items-center gap-2 relative">
                    <span>Email</span>

                    { collect_email === 'Required' && (
                      <div className="text-gray-400">
                        <RiAsterisk className='w-4 h-4 text-ssred' />
                      </div>
                    )}
                    { errors.email && <div className="text-red-500 text-sm">{errors.email.message}</div> }
                  </div>
                </label>
                <input
                  type='email'
                  placeholder='hello@example.com'
                  {...register("email", {
                    required: collect_email === 'Required',
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Entered value does not match email format",
                    },
                  })}
                  spellCheck='false'
                  className= 'border border-gray-300 rounded-md px-3 py-2 w-full placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-ssblue' />
              </div>
            )}
            { collect_job_title !== 'Do not collect' && (
              <div>
                <label className='mt-4 sm:mt-0 block text-sm font-medium text-gray-500'>
                  <div className="flex items-center gap-2 relative">
                    <span>Job Title</span>

                    { collect_job_title === 'Required' && (
                      <div className="text-gray-400">
                        <RiAsterisk className='w-4 h-4 text-ssred' />
                      </div>
                    )}
                    { errors.title && <div className="text-red-500 text-sm">This field is required</div> }
                  </div>
                </label>
                <input
                  type='text'
                  placeholder='Designer'
                  {...register("title", { required: collect_job_title === 'Required' })}
                  spellCheck='false'
                  className= 'border border-gray-300 rounded-md px-3 py-2 w-full placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-ssblue' />
              </div>
            )}
            { collect_company_name !== 'Do not collect' && (
              <div>
                <label className='mt-4 sm:mt-0 block text-sm font-medium text-gray-500'>
                  <div className="flex items-center gap-2 relative">
                    <span>Company</span>

                    { collect_company_name === 'Required' && (
                      <div className="text-gray-400">
                        <RiAsterisk className='w-4 h-4 text-ssred' />
                      </div>
                    )}
                    { errors.company_name && <div className="text-red-500 text-sm">This field is required</div> }
                  </div>
                </label>
                <input
                  type='text'
                  {...register("company_name", { required: collect_company_name === 'Required' })}
                  placeholder='Acme Bricks Inc'
                  spellCheck='false'
                  className= 'border border-gray-300 rounded-md px-3 py-2 w-full placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-ssblue' />
              </div>
            )}
            { collect_company_team !== 'Do not collect' && (
              <div>
                <label className='mt-4 sm:mt-0 block text-sm font-medium text-gray-500'>
                  <div className="flex items-center gap-2 relative">
                    <span>Company Team</span>

                    { collect_company_team === 'Required' && (
                      <div className="text-gray-400">
                        <RiAsterisk className='w-4 h-4 text-ssred' />
                      </div>
                    )}
                    { errors.company_team && <div className="text-red-500 text-sm">This field is required</div> }
                  </div>
                </label>
                <input
                  type='text'
                  {...register("company_team", { required: collect_company_team === 'Required' })}
                  placeholder='Design'
                  spellCheck='false'
                  className= 'border border-gray-300 rounded-md px-3 py-2 w-full placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-ssblue' />
              </div>
            )}
            { collect_company_logo !== 'Do not collect' && (
              <div className='col-span-2'>
                <label className='mt-4 sm:mt-0 block text-sm font-medium text-gray-500'>
                  <div className="flex items-center gap-2 relative">
                    <span>Company Logo</span>
                    { collect_company_logo === 'Required' && (
                      <div className="text-gray-400">
                        <RiAsterisk className='w-4 h-4 text-ssred' />
                      </div>
                    )}
                  </div>
                </label>
                <ImageUploader initialImageUrl={company_logo} setUrl={handleCompanyLogoChange} />
              </div>
            )}
          </div>
        </div>
        <button onClick={handleSubmit(onSubmit)} className="group relative inline-flex h-10 items-center justify-center overflow-hidden rounded-md bg-ssblue px-3 py-2 text-white border-2 border-black text-sm">
          <span className="absolute h-0 w-0 bg-ssblue-alt transition-all duration-300 group-hover:h-full group-hover:w-full"></span>
          <span className="relative">
            All done
          </span>
        </button>
      </div>
    </motion.div>
  </AnimatePresence>
};
