import React from 'react'
import { createRoot } from 'react-dom/client'
import { CapformShow } from "./../capform/Show";

// used for rendering the composition in the live app
//registerRoot(RemotionRoot);

const attachReact = (el, cb) => {
  const node = document.getElementById(el)
  if (node) { cb(node) }
}

document.addEventListener('turbo:load', () => {
  attachReact('react-capform-show', function (node) {
    const root = createRoot(node)
    root.render(<CapformShow {...JSON.parse(node.getAttribute('data')) } />)
  })
  //attachReact('react-video-form', function (node) {
    //const root = createRoot(node)
    //root.render(<VideoEdit {...JSON.parse(node.getAttribute('data')) } />)
  //})
  //attachReact('react-video-new', function (node) {
    //const root = createRoot(node)
    //console.log('loading react-video-new from turbo:load')
    //root.render(<VideoNew {...JSON.parse(node.getAttribute('data')) } />)
  //})
})
